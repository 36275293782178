<!-- 客服页 -->
<template>
    <div class="got" v-if="got">
        <PageHeader v-if="showHeader" title="联系客服" @return="back()" />
        <div class="edit-content" v-if="content" v-html="content"></div>
        <div v-else class="wrap">
            <p class="info">
                亲爱的用户，您好，如您对充值账单或功能使用等产生疑问，请添加QQ号“1937268397”联系人工客服咨询。
                <strong>请准备您的用户ID，方便我们快速定位您的问题。</strong>
            </p>
            <p class="info">服务时间：周一至周日9:30-12:00，13:30-19:00</p>
            <br />
            <p class="info"><strong>如何查看用户ID？</strong></p>
            <p class="info">点开个人中心，红框内部分为您的用户ID。</p>
            <img
                class="info-img"
                :src="'https://f2.kkmh.com/game/210524/q1fRcvh9y.png' | compress(600)"
                alt=""
            />
            <p class="info"><strong>单章消耗书币数量</strong></p>
            <p class="info">因单章篇幅尺寸不一，单章消耗书币数量为180-250</p>
        </div>
    </div>
</template>

<script>
import { get } from '@/modules/request.js';
import { mapState } from 'vuex';
import PageHeader from '@/components/header';

export default {
    data: function () {
        return {
            content: '',
            got: false,
            showHeader: false,
        };
    },
    name: 'CustomerService',
    computed: {
        ...mapState(['staticInfo']),
    },
    components: {
        PageHeader,
    },
    methods: {
        back() {
            this.$router.back();
        },
        getServiecInfo() {
            return get({
                url:
                    process.env.VUE_APP_DISTRIBUTION_HOST +
                    '/distribution/sales/wechat_customer_service_page',
            })
                .then((res) => {
                    if (res.code !== 200) {
                        return;
                    }
                    this.content = res.data.content;
                })
                .catch((err) => {
                    console.error({ err });
                    return Promise.resolve();
                });
        },
    },
    async created() {
        await this.getServiecInfo();
        this.got = true;
        document.title = '客服中心';
        loading.hide();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            if (from && from.name) {
                vm.showHeader = true;
            }
        });
    },
};
</script>

<style lang="less" scoped>
@import '~@/assets/vws.less';

.got {
    height: 100vh;
}

.wrap {
    padding: 20px;
    padding-bottom: 0;
    font-size: 14px;
    color: #333;

    .info {
        font-weight: 400;
    }

    .info-img {
        display: block;
        margin: 10px 0 20px;
        width: 100%;
    }
}
.edit-content {
    padding: 18px 18px 0;
}
.edit-content /deep/ p {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
}
.edit-content /deep/ img {
    display: block;
    margin: 5px 0 10px;
    max-width: 100%;
}
.edit-content /deep/ p .ql-size-huge {
    font-size: 2.5em;
}
.edit-content /deep/ p .ql-size-large {
    font-size: 1.5em;
}
.edit-content /deep/ p .ql-size-small {
    font-size: 1em;
}

/* 框架只支持 8个缩进符号 */
.edit-content /deep/ .ql-indent-1 {
    padding-left: 3em;
}
.edit-content /deep/ .ql-indent-2 {
    padding-left: 6em;
}
.edit-content /deep/ .ql-indent-3 {
    padding-left: 9em;
}
.edit-content /deep/ .ql-indent-4 {
    padding-left: 12em;
}
.edit-content /deep/ .ql-indent-5 {
    padding-left: 15em;
}
.edit-content /deep/ .ql-indent-6 {
    padding-left: 18em;
}
.edit-content /deep/ .ql-indent-7 {
    padding-left: 21em;
}
.edit-content /deep/ .ql-indent-8 {
    padding-left: 24em;
}
</style>
